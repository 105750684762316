@import './../variables';

.common {
    .slick-slider {
        .slick-list {
            .slick-slide {
                padding: 0.3rem;

                .card {
                    border-radius: 0.4rem;
                    .card-content {
                        h6 {
                            text-transform: capitalize;
                            font-size: 0.8rem;
                        }

                        p {
                            font-size: 0.7rem;
                        }
                    }
                }
            }

        }
    }
}

// .title {
//     &.line {
//         padding-left: 0.8rem;
//         position: relative;

//         &:before {
//             width: 3px;
//             height: 17px;
//             border-radius: 2px;
//             left: 0;
//             top: 3px;
//             background: #ff4e50;
//             background: linear-gradient(0deg, #f9d423, #ff4e50);
//             content: '';
//             position: absolute;
//         }
//     }
// }

.is-theme {
    background-color: $theme-button-color;
    color: white;
    border-color: none;
    border-width: 0;
}
.slider-card
{
    background-color: $card-bg;
    border-radius: 0.25rem;
    color: #4a4a4a;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    color:white;
}
  
.dot-outer{
    background-color: none;
    width: 20px !important;
    height: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dot {
    background-color: rgba($color: #fff, $alpha: 0.6);
    border-color:  rgba($color: #fff, $alpha: 0.6) !important;
    border-radius: 1rem !important;
    padding:4px;
    border-radius: 50%;
  }
   
  .slick-dots li {
    width: 15px !important;
  }
  
  .slick-active .dot {
    width:25px;
    background: white !important;
    border-color: white !important;
  }
  
  .slick-slider {
    flex-grow: 1 !important;
    height: 100%;
  }
  
  .slick-list,
  .slick-track {
    height: 100%;
    overflow: hidden;
  }
  
  .slick-slide > div {
    height: 100% !important;
  }
  .slider-container {
    pointer-events: none;
  }
  .slider-container .slick-dots {
    pointer-events: auto;
  }