$theme-button-color: #c40c77;
$font: Roboto, 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
$theme-gray:#e3e4e4;
$theme-rustyOrange:#FF7600;
$theme-darkOrange:#8B040E;
$theme-lightdarkOrange:#D51C2A;
$theme-lightrustyOrange:#ECB300;
$theme-lightYellow:#fbd35e;
$theme-lightOrange:#f25b67;
$theme-lightPurple:#6C69F6;
$theme-neonGreen:#39F8BA;
$theme-darkBlue:#001879;
$theme-lightBlue:#001C79;
$theme-babyBlue:#72B7FF;
$theme-neonPink:#F017FF;
$theme-neonBlue:#3E73FE;
$theme-neonSky:#00BCFA;
$theme-header-darkBlue:#001C79;
$theme-header-lightBlue:#CAD6FF;
$theme-header-neonBlue:#001C79;
$theme-header-lightBlue:#72B7FF;
$neon-blue:#0080FF;
$neon-green:#00908B;
$card-bg:#2B3180;
$slider-card-top-bg:#003D83;
$slider-card-bottom-bg:#077FAC;
$slider-active-card-top-bg:#6C69F6;
$slider-active-card-bottom-bg:#1B0F88;