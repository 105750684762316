@import "../variables";

.subContainer
{
    color:white;
    .status-img{
        height: 150px;
        padding:20px;
    }
    h1{
       font-size: 1.5rem;
       font-weight: 500;
       margin-bottom: 1rem;
    }
    p{
       color:$theme-neonGreen;
       font-size: 1rem;
       margin-bottom: 0.5rem;
    }
    h2{
        font-size: 1rem;
        // margin-bottom: 1rem;
     }
    button
    {
        margin-top:1.5rem;
    }
}
