@import "./../variables";
.game-container
{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.game-info-card
{
   background:$card-bg;
   padding:5px;
   border-radius: .5rem !important;
   width:49%;
   color:white;
   margin-bottom: 1rem;
}
.banner
{
    width: 100%;
    height: 60%;
}
.card-info
{
  text-align: left;
  h3
  {
    font-size: 1rem;
    font-weight: 800;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p
  {
    color:$theme-neonGreen !important;
  }
}
span{
  white-space: normal;
  word-wrap: break-word;
}