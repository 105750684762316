@import './../variables';
%margin-center {
    margin-left: auto !important;
    margin-right: auto !important;
  }
%h-auto {
    height: auto;
}
.btn-main {
    background-color: $theme-button-color;
    color: #fff;
    font-size: 1rem !important;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 2rem;
}
.container {
    .card {
        border-radius: 1.2rem;
        figure {
            &.z-s {
                max-width: 70px;
                @extend %h-auto;
            }
            &.l-s {
                max-width: 160px;
                @extend %h-auto;
            }
            &.s-s {
                max-width: 150px;
                @extend %h-auto;
            }
            @extend %margin-center;
        }
    }
}