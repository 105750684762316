@import "../variables";
.pop-bg
{
    background: rgba(35, 35, 35, 0.8);
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 10;    
}
.popup-modal
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:90%;
    padding:30px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    background: $card-bg;
    border-radius: 10px;
}
.popup-modal2
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:85%;
    padding:30px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    background: $card-bg;
    border-radius: 10px;
}

.max-w
{
    width:12rem;
}
.modal-content
{
    width:80%;
    font-size:1rem;
    font-weight: 800;
    word-wrap: break-word;
    white-space: pre-line;
    color:white;
    line-height: 2;
}
.model-close{
    position: absolute;
    right:0;
    top:0;
    padding:.5rem;
    height:40px;
  }