@import "../variables";
%anchor-display {
    vertical-align: baseline;
    display: inline-block;
}
.pagebanner {
    .p-r {
        position: relative;
    }
    a {
        &.navitem {
            @extend %anchor-display;
            position: absolute;
            z-index: 1;
            left: 10px;
            top: 10px;
        }
    }
    figure {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.65)), color-stop(100%, rgba(0, 0, 0, 0)));
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
        }
    }

    // .fixb {
        .media {
            // margin-top:40px;
            display: flex ;
            width: 100%;
            padding: 0.75rem;
            justify-content: space-between;
            .media-right {
                margin-left: auto;
                align-self: center;

                a {
                    @extend %anchor-display;
                }
            }
            h6
            {
                color:$theme-neonGreen !important;
                
            }
        }
    // }

}
.row
{
    height:50vh;
}

.play-btnn
{
   height: 60px;
   width:60px;
   cursor: pointer;
}
.desc
{
    // position: absolute;
    bottom:10px;
}
.fixb
{
    // margin-top:20px;
}

.banner-container {
    position: relative;
  }
  
  .back-button {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .service-logo {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 50px;
  }
  