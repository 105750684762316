@import "../variables";

.top-slider-card
{
   background: linear-gradient($slider-card-top-bg,$slider-card-bottom-bg);
   padding:1rem 0;
   border:1px solid $slider-card-bottom-bg;
   display: flex;
   flex-direction: column;
   justify-content: center;
   white-space: normal;  
   text-overflow: ellipsis;
   align-items: center;
   height: 110px;
   span
   {
    font-size: 1rem;
    text-align: center;
    display: block;
    word-break: break-word;
    text-align: center;
   }
   img{
     height: 50px;
   }
}
.top-slider-card-selected
{
    background: linear-gradient($slider-active-card-top-bg,$slider-active-card-bottom-bg);
}
