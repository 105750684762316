@import './../variables';

.image
{
  padding:0;
}
.form-container
{
    padding:0 1rem;
}
.msisdn-field
{
    display: flex;
    width:100%;
    flex-direction: row;
    border-radius: 0.5rem;
    background-color: $card-bg;
    border:1px solid white;
    p
    {
        background-color: $card-bg !important;
        color:white;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border:none !important;
        outline: none !important;
    }
}
.subMsisdn
{
    display: flex;
    width:100%;
    flex-direction: row;
    background-color: $theme-header-darkBlue;
    border:3px solid white;
    p
    {
        background-color: $theme-header-darkBlue !important;
        color:white;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border:none !important;
        outline: none !important;
        margin: auto;
    }
    div
    {
        padding:0.25rem;
        width: 100%;
        input{
        border:1px solid white;
        border-radius:0.5rem !important ;
    }
    }
}
.inputBox
{
    display: flex;
    flex-direction: column;
    label
    {
         color:white;
         padding:.75rem 0rem;
         font-size: 1rem;
         font-weight: 400;
    }
    input
    {
        padding:.75rem;
        border-radius: 0.5rem;
        background-color: $card-bg !important;
        border: none;
        outline: none;
        color:white;
        text-align: center;
        width: 100%;
        &:focus
        {
            outline: none;
        }
    }
}
#otp
{
    border:1px solid white;
}
#msisdn
{
    border-left:1px solid white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.singUp{
    color:white;
    text-align: center; 
    padding:.75rem 0;
    font-size: 1rem;
    a
    {
        color:$theme-neonGreen;
        font-weight: 400;
        text-decoration: underline;
    }
}
.text{
    width: 90%;
    margin: auto;
    text-align: center;
    color: $theme-neonGreen;
    font-size: 1.5rem;
    font-weight: 600;
}
.pack-container
{
    margin: 1rem 1rem;
}
.pack
{
    background-color:$card-bg ;
    padding:2rem;
    border-radius: .5rem;
    margin: auto;
    margin-bottom: .75rem;
    text-align: center;
    color:white;
    position: relative;
    h3
    {
        font-size: 1rem;
        font-weight: 600;
    }
    p
    {
        font-size: 1.5rem;
        font-weight: 900;
    }
}
.pack-selected
{
   background:linear-gradient(to top,$neon-blue,$neon-green);
}
.pack-selected::before {
  content: '';
  position: absolute;
  top: 3px; 
  bottom: 3px; 
  left: 3px;
  right: 3px;
  border-radius: 5px; 
  border: .5px solid white; 
  pointer-events: none; 
  box-sizing: border-box; 
}
input::placeholder {
    color: #cecece;
}
.mainTitle
{
    padding-top:1.5rem;
    padding-bottom: 1rem;
    color:white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    h1{
        font-size: 1.25rem;
        font-weight: 400;
    }
    img{
        height: 20px;
    }
}