@import '../variables';
.sideMenu {
    width: 300px;
    height: 100%;
    background: $card-bg;
    z-index: 99 !important;
    min-height: 100vh;
    color:white;
    border-radius:0 0 9% 0%;
}
.line-bottom {
    border-bottom: 1px solid #fff;
}
.navitem p{
    font-size:1.5rem;
    margin: auto;
    margin-left: 0.5rem;
}
.menu-logo-img
{
    background-image: url("../assets/icons/hamburger_menu_bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .sidemenu-icon
    {
        height: 60px;
    }
}
.menu-icon
{
    height: 30px;
}
.span
{
     color:white !important;
}
.logo
{
    height: 40px !important;
}