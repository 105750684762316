.container {
    .slick-slider {
        .slick-prev {
            left: -15px;
        }
        .slick-next {
            right: -15px;
        }
        .slick-dots {
            // bottom: 8px;
            li {
                button:before {
                    font-size: 0.8rem;
                }
            }
        }
    }
}